import { useRef, useEffect, useState, useCallback } from "react";
import * as THREE from "three";
import { useTheme } from "./ThemeProvider";
import { useBottomNavbar } from "./BottomNavbar";

// Import shader files with ?raw suffix for Vite's HMR
import vertexShaderRaw from "../shaders/heroShader.vert?raw";
import fragmentShaderRaw from "../shaders/heroShader.frag?raw";

// Define fallback shaders in case the imports fail
const fallbackVertexShader = `
varying vec2 vUv;
void main() {
  vUv = uv;
  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`;

const fallbackFragmentShader = `
precision mediump float;
uniform float iTime;
uniform vec2 iResolution;
uniform float iLightTheme;
varying vec2 vUv;

void main() {
  vec2 uv = vUv;
  float color = 0.1 / length(fract(uv) - 0.5);
  
  vec3 finalColor = vec3(color);
  
  // Theme-based adjustments
  if (iLightTheme > 0.5) {
    // Light theme
    finalColor = 1.0 - finalColor;
  }
  
  gl_FragColor = vec4(finalColor, 1.0);
}
`;

// Simple throttle function to limit function execution rate
const throttle = (func, limit) => {
  let inThrottle;
  return function (...args) {
    if (!inThrottle) {
      func.apply(this, args);
      inThrottle = true;
      setTimeout(() => {
        inThrottle = false;
      }, limit);
    }
  };
};

const ShaderMaterialCanvas = () => {
  const canvasRef = useRef(null);
  const rendererRef = useRef(null);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const materialRef = useRef(null);
  const animationFrameRef = useRef(null);
  const containerRef = useRef(null);
  const startTimeRef = useRef(Date.now());
  const [hasShaderError, setHasShaderError] = useState(false);
  const { theme } = useTheme();
  const { activeIndex } = useBottomNavbar();

  // Animation ramp state for transitions
  const [rampValue, setRampValue] = useState(0);
  const rampAnimationRef = useRef(null);
  const animValueFrameRef = useRef(null); // Add reference for radius/rotation animation
  const lastIndexChangeTime = useRef(0);
  const animationDuration = 1500; // 1500ms duration

  // Track scroll position for vertical shader repetition
  const scrollYRef = useRef(0);

  // Use imported shaders directly with a fallback
  const [shaders, setShaders] = useState({
    vertex: vertexShaderRaw || fallbackVertexShader,
    fragment: fragmentShaderRaw || fallbackFragmentShader,
  });

  const previousTimeRef = useRef(0);
  const previousScrollYRef = useRef(0);
  const frameRateRef = useRef(1000 / 60); // Target ~60 FPS (16.67ms per frame)

  // Setup hot module replacement for shader files
  useEffect(() => {
    // For HMR support - only in development
    if (import.meta.hot) {
      // Handle hot updates for vertex shader
      import.meta.hot.accept(
        "../shaders/heroShader.vert?raw",
        (newVertexModule) => {
          const newVertexShader =
            newVertexModule?.default || fallbackVertexShader;

          setShaders((prev) => ({
            ...prev,
            vertex: newVertexShader,
          }));

          // Update material immediately if it exists
          if (materialRef.current) {
            materialRef.current.vertexShader = newVertexShader;
            materialRef.current.needsUpdate = true;
          }
        }
      );

      // Handle hot updates for fragment shader
      import.meta.hot.accept(
        "../shaders/heroShader.frag?raw",
        (newFragModule) => {
          const newFragShader =
            newFragModule?.default || fallbackFragmentShader;

          setShaders((prev) => ({
            ...prev,
            fragment: newFragShader,
          }));

          // Update material immediately if it exists
          if (materialRef.current) {
            materialRef.current.fragmentShader = newFragShader;
            materialRef.current.needsUpdate = true;
          }
        }
      );
    }
  }, []);

  // Find the hero section for dimensions
  useEffect(() => {
    // Function to find the hero section element
    const findHeroElement = () => {
      return document.getElementById("hero");
    };

    containerRef.current = findHeroElement();

    // If hero section doesn't exist yet, retry after a short delay
    if (!containerRef.current) {
      const timeoutId = setTimeout(() => {
        containerRef.current = findHeroElement();
        if (containerRef.current && rendererRef.current) {
          resizeCanvas();
        }
      }, 100);

      return () => clearTimeout(timeoutId);
    }
  }, []);

  // Trigger unified animation when activeIndex changes
  useEffect(() => {
    // Cancel any existing animations
    if (rampAnimationRef.current) {
      cancelAnimationFrame(rampAnimationRef.current);
      rampAnimationRef.current = null;
    }
    if (animValueFrameRef.current) {
      cancelAnimationFrame(animValueFrameRef.current);
      animValueFrameRef.current = null;
    }

    if (!materialRef.current || !materialRef.current.uniforms) return;

    // Calculate radius and rotation values
    const radValues = [0.85, 0.6, 0.7, 0.8];
    const targetRadMultiplier = radValues[activeIndex % radValues.length];

    // Use a smaller rotation range to prevent dramatic jumps
    const randomRotation = ((Math.random() * 2 - 1) * Math.PI) / 8.0;

    // Set up animation starting values
    const startTime = performance.now();
    lastIndexChangeTime.current = startTime;

    // Get current values safely, with fallbacks
    const currentRad = materialRef.current.uniforms.iRadMultiplier?.value;
    const currentRotation = materialRef.current.uniforms.iRotation?.value;

    const startRad = typeof currentRad === "number" ? currentRad : radValues[0];
    const startRotation =
      typeof currentRotation === "number" ? currentRotation : 0;

    // Update navbar index immediately to avoid delay
    if (materialRef.current.uniforms.iNavbarIndex) {
      materialRef.current.uniforms.iNavbarIndex.value = activeIndex;
    }

    // Unified animation function that handles both ramp and radius/rotation animations
    const animateUnified = (timestamp) => {
      const elapsed = timestamp - startTime;

      // If animation is complete, set final values and exit
      if (elapsed >= animationDuration) {
        // Only reset the ramp value to 0, NOT the radius and rotation
        setRampValue(0);

        if (materialRef.current && materialRef.current.uniforms) {
          // Ensure these values are explicitly set
          materialRef.current.uniforms.iRadMultiplier.value =
            targetRadMultiplier;
          materialRef.current.uniforms.iRotation.value = randomRotation;
        }

        rampAnimationRef.current = null;
        return;
      }

      // Calculate base progress
      const progress = elapsed / animationDuration;
      let rampValue;

      // Calculate ramp value (0→1→0 transition)
      if (progress < 0.5) {
        // 0 to 1 (first half) - ease in
        const normalizedProgress = progress * 2; // rescale to 0-1 range
        // Cubic easing function
        rampValue =
          normalizedProgress *
          normalizedProgress *
          normalizedProgress *
          (10 -
            15 * normalizedProgress +
            6 * normalizedProgress * normalizedProgress);
      } else {
        // 1 to 0 (second half) - ease out
        const normalizedProgress = (progress - 0.5) * 2; // rescale to 0-1 range
        // Cubic easing function (reversed)
        rampValue =
          1 -
          normalizedProgress *
            normalizedProgress *
            normalizedProgress *
            (10 -
              15 * normalizedProgress +
              6 * normalizedProgress * normalizedProgress);
      }

      // Update ramp value state
      setRampValue(rampValue);

      // Calculate radius/rotation easing - use a very simple linear interpolation
      // to prevent any jumps or unexpected curve behavior
      const radiusProgress = Math.min(1.0, progress * 1.2); // slightly faster than ramp

      // Update radius and rotation values
      if (materialRef.current && materialRef.current.uniforms) {
        // Interpolate radius with linear interpolation for stability
        materialRef.current.uniforms.iRadMultiplier.value =
          startRad + (targetRadMultiplier - startRad) * radiusProgress;

        // Interpolate rotation with same linear approach
        materialRef.current.uniforms.iRotation.value =
          startRotation + (randomRotation - startRotation) * radiusProgress;
      }

      // Continue animation
      rampAnimationRef.current = requestAnimationFrame(animateUnified);
    };

    // Start the unified animation
    rampAnimationRef.current = requestAnimationFrame(animateUnified);

    // Return cleanup function - this is important for when component unmounts
    return () => {
      // Cancel any running animation
      if (rampAnimationRef.current) {
        cancelAnimationFrame(rampAnimationRef.current);
        rampAnimationRef.current = null;
      }

      // Important: make sure we leave the uniforms with their final values
      if (materialRef.current && materialRef.current.uniforms) {
        materialRef.current.uniforms.iRadMultiplier.value = targetRadMultiplier;
        materialRef.current.uniforms.iRotation.value = randomRotation;
      }
    };
  }, [activeIndex, animationDuration]);

  // Memoize the resize handler with useCallback
  const resizeCanvas = useCallback(() => {
    if (!rendererRef.current || !materialRef.current) return;

    // Use main content area for shader instead of just hero section
    const mainContentElement = document.querySelector("main");
    const pixelRatio = Math.min(window.devicePixelRatio, 1);

    if (!mainContentElement) {
      // If main content not found, use viewport dimensions as fallback
      const width = Math.min(window.innerWidth, 1920); // Max width of 1920px
      const height = window.innerHeight;

      // Set renderer size accounting for pixel ratio
      rendererRef.current.setSize(width, height, false);
      rendererRef.current.setPixelRatio(pixelRatio);

      if (materialRef.current.uniforms) {
        // Update shader uniforms with actual pixel dimensions
        materialRef.current.uniforms.iResolution.value.set(
          width * pixelRatio,
          height * pixelRatio
        );
        if (materialRef.current.uniforms.iScrollY) {
          materialRef.current.uniforms.iScrollY.value = scrollYRef.current;
        }
      }
    } else {
      // Get the dimensions of the main content area
      const rect = mainContentElement.getBoundingClientRect();
      const width = Math.min(rect.width, 1920); // Max width of 1920px
      const height = window.innerHeight;

      // Set renderer size accounting for pixel ratio
      rendererRef.current.setSize(width, height, false);
      rendererRef.current.setPixelRatio(pixelRatio);

      if (materialRef.current.uniforms) {
        // Update shader uniforms with actual pixel dimensions
        materialRef.current.uniforms.iResolution.value.set(
          width * pixelRatio,
          height * pixelRatio
        );
        if (materialRef.current.uniforms.iScrollY) {
          materialRef.current.uniforms.iScrollY.value = scrollYRef.current;
        }
      }

      // Position the canvas to match the main content's position
      if (canvasRef.current) {
        canvasRef.current.style.position = "fixed";
        canvasRef.current.style.top = "0px";
        canvasRef.current.style.left = "50%";
        canvasRef.current.style.transform = "translateX(-50%)";
        canvasRef.current.style.width = `${width}px`;
        canvasRef.current.style.height = `${height}px`;
        canvasRef.current.style.maxWidth = "1920px";
      }
    }

    // Update orthographic camera to fill the entire view
    if (cameraRef.current) {
      cameraRef.current.left = -1;
      cameraRef.current.right = 1;
      cameraRef.current.top = 1;
      cameraRef.current.bottom = -1;
      cameraRef.current.updateProjectionMatrix();
    }

    // Force a render to update the view immediately
    if (rendererRef.current && sceneRef.current && cameraRef.current) {
      rendererRef.current.render(sceneRef.current, cameraRef.current);
    }
  }, []);

  // Memoize the scroll handler with useCallback
  const handleScroll = useCallback(() => {
    scrollYRef.current = window.scrollY;
    // Update the canvas position on scroll
    resizeCanvas();
  }, [resizeCanvas]);

  // Memoize throttled handlers
  const throttledScrollHandler = useCallback(throttle(handleScroll, 16), [
    handleScroll,
  ]);

  const throttledResizeHandler = useCallback(throttle(resizeCanvas, 100), [
    resizeCanvas,
  ]);

  useEffect(() => {
    // Initialize the scene
    const initialize = () => {
      if (!canvasRef.current) return;

      try {
        // Create scene
        const scene = new THREE.Scene();
        sceneRef.current = scene;

        // Create camera
        const camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0.1, 100);
        camera.position.z = 1;
        cameraRef.current = camera;

        // Create renderer
        const renderer = new THREE.WebGLRenderer({
          canvas: canvasRef.current,
          antialias: true,
          alpha: true,
        });
        // Limit pixel ratio to 1.0 for better performance on high-DPI screens
        const pixelRatio = Math.min(window.devicePixelRatio, 1);
        renderer.setPixelRatio(pixelRatio);
        rendererRef.current = renderer;

        // Calculate appropriate radius for current index
        const radValues = [0.85, 0.6, 0.7, 0.8];
        const initialRadMultiplier = radValues[activeIndex % radValues.length];

        // Create shader material with error handling
        const uniforms = {
          iTime: { value: 0.0 },
          iResolution: { value: new THREE.Vector2(1, 1) },
          iLightTheme: { value: theme === "light" ? 1.0 : 0.0 },
          iScrollY: { value: 0.0 }, // Add scroll position uniform
          iNavbarIndex: { value: activeIndex }, // Add navbar index uniform
          iTransitionRamp: { value: 0.0 }, // Add transition ramp uniform
          iRadMultiplier: { value: initialRadMultiplier }, // Initialize with correct start value
          iRotation: { value: 0.0 }, // Add rotation uniform
        };

        const material = new THREE.ShaderMaterial({
          vertexShader: shaders.vertex,
          fragmentShader: shaders.fragment,
          uniforms,
        });

        materialRef.current = material;

        // Create plane geometry that fills the viewport
        const geometry = new THREE.PlaneGeometry(2, 2);

        // Create mesh
        const mesh = new THREE.Mesh(geometry, material);
        scene.add(mesh);

        // Set canvas size based on hero section
        resizeCanvas();

        // Start animation loop
        animate();
      } catch (error) {
        setHasShaderError(true);
      }
    };

    // Animation loop optimized to reduce unnecessary updates
    const animate = (timestamp) => {
      if (
        !rendererRef.current ||
        !sceneRef.current ||
        !cameraRef.current ||
        !materialRef.current
      )
        return;

      try {
        // Calculate time delta since last frame
        const deltaTime = timestamp - previousTimeRef.current;

        // Skip frame if not enough time has elapsed (targeting frameRate)
        // This helps reduce CPU/GPU load especially on high refresh rate monitors
        if (deltaTime < frameRateRef.current && previousTimeRef.current !== 0) {
          animationFrameRef.current = requestAnimationFrame(animate);
          return;
        }

        // Update time reference for next frame
        previousTimeRef.current = timestamp;

        const elapsedTime = (Date.now() - startTimeRef.current) / 1000;

        // Only update uniforms if they've changed or if time-dependent
        if (materialRef.current.uniforms) {
          // Time always updates
          materialRef.current.uniforms.iTime.value = elapsedTime;

          // Only update scroll position if it changed
          const currentScrollY = scrollYRef.current;
          if (Math.abs(currentScrollY - previousScrollYRef.current) > 0.5) {
            materialRef.current.uniforms.iScrollY.value = currentScrollY;
            previousScrollYRef.current = currentScrollY;
          }
        }

        // Always render the shader for the full page
        rendererRef.current.render(sceneRef.current, cameraRef.current);

        animationFrameRef.current = requestAnimationFrame(animate);
      } catch (error) {
        setHasShaderError(true);

        if (animationFrameRef.current) {
          cancelAnimationFrame(animationFrameRef.current);
        }
      }
    };

    // Initialize everything when component mounts and shaders are ready
    initialize();

    // Handle window resize with throttling
    window.addEventListener("resize", throttledResizeHandler);
    // Handle scroll for positioning and shader effect with throttling
    window.addEventListener("scroll", throttledScrollHandler);

    return () => {
      window.removeEventListener("resize", throttledResizeHandler);
      window.removeEventListener("scroll", throttledScrollHandler);

      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [
    shaders.vertex,
    shaders.fragment,
    theme,
    activeIndex,
    rampValue,
    throttledResizeHandler,
    throttledScrollHandler,
  ]);

  // Update theme when it changes
  useEffect(() => {
    if (materialRef.current && materialRef.current.uniforms) {
      materialRef.current.uniforms.iLightTheme.value =
        theme === "light" ? 1.0 : 0.0;
    }
  }, [theme]);

  // If there's a shader error, display a fallback gradient
  if (hasShaderError) {
    return (
      <div
        className="w-full h-full"
        style={{
          background:
            theme === "light"
              ? "linear-gradient(135deg, #ffffff 0%, #f5f5f5 100%)"
              : "linear-gradient(135deg, #121212 0%, #2d2d2d 100%)",
        }}
      />
    );
  }

  return (
    <>
      <canvas ref={canvasRef} className="w-full h-full" />
    </>
  );
};

export default ShaderMaterialCanvas;
