// Enhanced project data with multiple images for each project
export const allProjects = [
  {
    id: "project-1",
    title: "Einstein on the Beach",
    summary:
      "The production of Glass's -Einstein on the Beach- synchronized music with visuals in real-time, creating a synesthetic experience through digital landscapes and responsive projections.",
    details:
      "This innovative staging translated Glass's hypnotic minimalist music into visual impulses using custom shader applications. Real-time software synchronized choir text with projected images, rendered 50-million-point virtual landscapes, and transformed conductor movements into 3D visuals. The production featured transparent surgery tube curtains, electron-microscopic -Nano Flight- footage, and costume design evoking post-human cyborgs—all highlighting the theme of mechanical, non-human art creation. The critically acclaimed show topped nachtkritik.de charts during its six-week run.",
    credits: [
      { role: "Musical Direction", name: "Florian Helgath" },
      { role: "Director", name: "Kay Voges" },
      { role: "Stage Design", name: "Pia Maria Mackert" },
      { role: "Costume Design", name: "Mona Ulrich" },
      { role: "Visual Director", name: "Lars Ullrich" },
    ],
    label: "Visual Director",
    year: "2017",
    type: "Oper",
    href: "#",
    images: [
      "/images/60.jpg",
      "/images/11.jpg",
      "/images/20.jpg",
      "/images/25.jpg",
      "/images/26.jpg",
      "/images/30.jpg",
      "/images/32.jpg",
      "/images/61.jpg",
    ],
    image: "/images/60.jpg",
  },
  {
    id: "project-2",
    title: "ECM-Flow",
    summary:
      "An audiovisual installation that transforms ECM Records' distinctive musical horizontality into dynamic visual landscapes, allowing visitors to experience the inner movement of music through responsive 3D particle systems.",
    details:
      "Created for the RE:ECM exhibition at Storage by Hyundai Card in Seoul, 2019, ECM-Flow embodies the continuous forward motion and horizontal development that founder Manfred Eicher considers essential to ECM Records' musical philosophy. The installation generates abstract three-dimensional landscapes where particle systems form continuous streams of objects whose shapes, momentum, distribution, and velocity respond directly to the musical parameters of currently playing tracks. This creates an immersive environment where listeners can explore the flowing dynamics of the music and become part of its organic behavior. Seven repeating visualization styles interact with nine rotating musical selections, showcasing how each visual approach responds to different musical compositions. The carefully curated playlist spans ECM's diverse catalog, featuring historic milestone recordings from artists like Bennie Maupin, Paul Bley, and Arvo Pärt alongside contemporary works. A notable inclusion is Bruno Ganz's recording of Friedrich Hölderlin poetry, which represents a radical extension of the horizontal development concept by revealing similar forward motion in spoken language.",
    credits: [
      { role: "Concept & Visual Direction", name: "Lars Ullrich" },
      { role: "Music Curation", name: "Mathis Nitschke" },
      { role: "Exhibition Design", name: "Storage by Hyundai Card" },
    ],
    label: "Interactive Installation",
    year: "2019",
    type: "Audiovisual",
    href: "#",
    images: [
      "/images/63.jpg",
      "/images/14.jpg",
      "/images/45.jpg",
      "/images/62.jpg",
    ],
    image: "/images/04.jpg",
  },
  {
    id: "project-3",
    title: "The Sound of Porsche",
    summary:
      "A multi-sensory installation that brings the Porsche driving experience to life through spatial audio design and interactive technology across global pop-up events.",
    details:
      "Developed for international pop-up experiences across New York, London, Moscow, Seoul, and Shanghai, this project translated Porsche's brand values through immersive sound design. The centerpiece 'Soundlab' allowed visitors to take virtual drives in a Porsche 911 across iconic circuits including Miami, Nürburgring, and Kaunertaler Glacier Road. The experience featured 7.1 surround sound paired with 3D state-of-the-art visual mapping, with audio composed of field recordings and six distinct layers of engine and environmental sounds precisely synchronized via a custom application. Following sonic principles defined as iconic, seductive, heroic and beautiful, we created interactive journeys that placed the Porsche driving experience at the center. The project reached over 420,000 attendees across five countries and was recognized with a prestigious Red Dot Award in 2015 for its innovative approach to sonic branding.",
    credits: [
      { role: "Software Lead", name: "Lars Ullrich" },
      { role: "Sound Design", name: "Porsche Team" },
      { role: "Experience Design", name: "Porsche Brand" },
      { role: "UX/UI Development", name: "Lars Ullrich" },
    ],
    label: "System developer",
    year: "2015",
    type: "Installation",
    href: "#",
    images: ["/images/59.jpg", "/images/21.jpg", "/images/22.jpg"],
    image: "/images/59.jpg",
  },
  {
    id: "project-4",
    title: "Hemispheres",
    summary:
      "An immersive audio/video installation exploring the neurological relationship between chaos and harmony through synchronized dual projections on a large curved screen.",
    details:
      "Presented at Alte Münze Berlin, Germany, this installation divided a large curved screen into two halves, visually representing the brain's hemispheres. The project explored the neurological concept that perfect synchronization between brain hemispheres only occurs during specific states: deep coma, schizophrenic disorders, epileptic seizures, and death. Through five movements—Emission, Reflection, Cognition, Neurotransmitter, and Synchronicity—the installation translated complex neurological concepts into sensory experiences, demonstrating how 'chaos equals harmony and disorder is vital' from a neurological perspective. The project utilized advanced 3D spatial audio technology from Encircled-Audio with Iosono implementation to create a fully immersive environment where sound and visuals worked in tandem to illustrate the paradoxical relationship between neural chaos and cognitive harmony.",
    credits: [
      { role: "Music", name: "Lukas Taido" },
      { role: "Visual Arts", name: "Lars Ullrich" },
      { role: "3D-Audio", name: "Encircled-Audio" },
      { role: "Scientific Consultant", name: "Dr. Stephan Teufel" },
    ],
    label: "Immersive Installation",
    year: "2019",
    type: "Audio/Visual",
    href: "#",
    images: ["/images/07.jpg", "/images/17.jpg", "/images/41.jpg"],
    image: "/images/41.jpg",
  },
  {
    id: "project-5",
    title: "Legis 75",
    summary:
      "A commemorative video mapping installation on Munich's Town Hall at Marienplatz celebrating 75 years of democracy in Germany, combining architectural projection with live spoken elements.",
    details:
      "Commissioned by the city and mayor of Munich as an official event marking 75 years of democracy in Germany, this large-scale projection mapping transformed the iconic Town Hall at Marienplatz into a canvas for democratic reflection. The installation combined digital animations with carefully selected historical texts performed by live speakers, creating a dialogue between past and present democratic values. The project integrated architectural features of the historic building into its visual narrative, using them as symbolic elements that evolved throughout the performance. The work honored Germany's democratic journey through a thoughtful interplay of music, spoken word, and dynamic visuals that responded to both the building's structure and the thematic progression of the narrative. As a public installation in one of Munich's most prominent locations, it engaged citizens in a collective reflection on democratic principles and their evolution over three-quarters of a century.",
    credits: [
      { role: "Concept & Direction", name: "Lukas Taido" },
      { role: "Digital Animation", name: "Lars Ullrich" },
      { role: "Projection Mapping", name: "Mammasonica" },
      { role: "Technical Supervision", name: "Ronny Koetteritzsch" },
    ],
    label: "Projection Mapping",
    year: "2024",
    href: "#",
    images: [
      "/images/49.jpg",
      "/images/50.jpg",
      "/images/51.jpg",
      "/images/52.jpg",
    ],
    image: "/images/49.jpg",
  },
  {
    id: "project-6",
    title: "Micropsi Industries MIRAI",
    summary:
      "Advanced real-time robotics control software using machine learning to enable industrial robots to handle complex tasks with variation and unpredictability.",
    details:
      "At Micropsi Industries, I contributed to the development of MIRAI, an AI-powered vision system that revolutionizes how robots handle variations in production environments. The system enables robots to perform tasks previously impossible with conventional programming, working reliably even with reflective and transparent objects. My work focused on OpenGL implementation and software development, creating visual processing systems that allow the AI to interpret and respond to complex environments in real-time. MIRAI requires no CAD data, camera calibration, or controlled lighting conditions, making it exceptionally adaptable to changing production requirements. The technology has been adopted by major industrial leaders including Tesla, ZF, BSH Hausgeräte, Mercedes-Benz, and Siemens Energy for applications such as component insertion, end-of-line testing, and parts handling in dynamic environments.",
    credits: [
      { role: "Software Development", name: "Lars Ullrich" },
      { role: "OpenGL Implementation", name: "Lars Ullrich" },
      { role: "AI Systems", name: "Micropsi Team" },
      { role: "Robotics Engineering", name: "Micropsi Industries" },
    ],
    label: "Industrial AI",
    year: "2019",
    type: "Software",
    href: "#",
    images: [
      "/images/58.jpg",
      "/images/35.jpg",
      "/images/19.jpg",
      "/images/01.jpg",
      "/images/37.jpg",
    ],
    image: "/images/58.jpg",
  },
  {
    id: "project-7",
    title: "My Lonesome Hologram",
    summary:
      "An interactive VR installation where visitors are captured as 3D holograms and placed into a virtual island environment, creating an evolving narrative landscape of human presence.",
    details:
      "Presented at DOK Leipzig and HFF München, MY LONESOME HOLOGRAM is an interactive virtual reality installation that captures visitors and transforms them into digital presence within a shared virtual world. The experience unfolds in three distinct phases: first, visitors participate in a brief interview; second, they are recorded with dual 3D cameras for approximately 40 seconds; finally, they don VR glasses to explore a virtual island populated by their own hologram and those of previous participants. As visitors navigate the island, they encounter a randomly composed arrangement of holograms and audio fragments from interviews, creating a unique narrative experience that evolves with each new participant. The installation's technical implementation required sophisticated real-time 3D capture, audio processing, and virtual environment design to create a seamless integration between the physical visitors and their digital representations. Each island visit lasts between 10-20 minutes, with visitors guided individually through the experience in an exhibition space of at least 20 square meters.",
    credits: [
      { role: "Director", name: "Daniel Hengst" },
      { role: "Technical Implementation", name: "Lars Ullrich" },
      { role: "Production", name: "Nina Wesemann" },
      { role: "Technical Support", name: "Frank Génot" },
    ],
    label: "Documentary VR",
    year: "2016",
    type: "Installation",
    href: "#",
    images: ["/images/53.jpg", "/images/54.jpg", "/images/55.jpg"],
    image: "/images/55.jpg",
  },
];
