import { ThemeProvider } from "./components/ThemeProvider";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Section from "./components/Section";
import Hero from "./components/pages/Hero";
import Gallery from "./components/pages/Gallery";
import AboutPage from "./components/pages/AboutPage";
import ProjectView from "./components/pages/ProjectView";
import ShaderMaterialCanvas from "./components/ShaderMaterialCanvas";
import { BottomNavbar, NavbarProvider } from "./components/BottomNavbar";
import TopNavbar from "./components/TopNavbar";
import "./input.css";

function App() {
  return (
    <Router>
      <ThemeProvider>
        <NavbarProvider>
          <div className="flex flex-col min-h-screen bg-background/80">
            {/* ShaderMaterialCanvas for the entire page */}
            <div className="fixed inset-0 w-full h-full z-0 pointer-events-none">
              <ShaderMaterialCanvas />
            </div>

            {/* Global design elements overlay */}

            {/* Top navigation bar */}
            <TopNavbar />

            {/* Main content */}
            <div className="w-full mx-auto relative z-20 flex-grow">
              <Routes>
                {/* Home Route */}
                <Route
                  path="/"
                  element={
                    <main className="w-full bg-transparent text-foreground">
                      {/* Hero Section - Full screen */}
                      <Section
                        id="hero"
                        className="bg-transparent h-screen w-screen"
                        alignLeft={true}
                        fullWidth={true}
                        containerClassName="h-full px-4 md:px-6 lg:px-8"
                      >
                        <Hero />
                      </Section>
                    </main>
                  }
                />

                {/* Gallery Route */}
                <Route
                  path="/gallery"
                  element={
                    <main className="w-full bg-transparent text-foreground">
                      <Section
                        id="gallery"
                        className="bg-transparent min-h-screen"
                        alignLeft={true}
                        fullWidth={true}
                        containerClassName="h-full px-4 md:px-6 lg:px-8"
                      >
                        <Gallery />
                      </Section>
                    </main>
                  }
                />

                {/* About Route */}
                <Route
                  path="/about"
                  element={
                    <main className="w-full bg-transparent text-foreground">
                      <Section
                        id="about"
                        className="bg-transparent min-h-screen"
                        alignLeft={true}
                        fullWidth={true}
                        containerClassName="h-full px-4 md:px-6 lg:px-8"
                      >
                        <AboutPage />
                      </Section>
                    </main>
                  }
                />

                {/* Projects Route */}
                <Route
                  path="/projects"
                  element={
                    <main className="w-full bg-transparent text-foreground">
                      <Section
                        id="projects"
                        className="bg-transparent min-h-screen"
                        alignLeft={true}
                        fullWidth={true}
                        containerClassName="h-full px-4 md:px-6 lg:px-8"
                      >
                        <ProjectView />
                      </Section>
                    </main>
                  }
                />
              </Routes>
            </div>

            {/* Bottom Navbar - the only navigation we need */}
            <BottomNavbar />
          </div>
        </NavbarProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
