import React, { useState } from "react";
import { GitHubLogoIcon, LinkedInLogoIcon, HamburgerMenuIcon, Cross1Icon } from "@radix-ui/react-icons";
import { ContactModal } from "./modals/ContactModal";

const TopNavbar = () => {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <div className="fixed top-0 z-50 w-full p-2 sm:p-4 flex justify-between">
      {/* Contact button on the left - hidden on small mobile devices */}
      <div className="hidden sm:block pl-4 md:pl-8 lg:pl-28">
        <button
          onClick={() => setIsContactModalOpen(true)}
          className="text-white/70 hover:text-white transition-colors cursor-pointer text-xs uppercase tracking-widest h-12 px-3 flex items-center justify-center"
        >
          Contact
        </button>
      </div>

      {/* Mobile menu button - only visible on small screens */}
      <div className="sm:hidden pl-4">
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          aria-label="Toggle menu"
          className="text-white/70 hover:text-white transition-colors h-12 w-12 flex items-center justify-center"
        >
          {isMobileMenuOpen ? (
            <Cross1Icon className="w-5 h-5" />
          ) : (
            <HamburgerMenuIcon className="w-5 h-5" />
          )}
        </button>
      </div>

      {/* Social icons on the right */}
      <div className="flex items-center space-x-2 sm:space-x-4 pr-4 md:pr-8 lg:pr-24">
        <a
          href="https://github.com/Vllrich"
          aria-label="GitHub"
          className="text-white/70 hover:text-white transition-colors cursor-pointer h-12 w-12 flex items-center justify-center"
          target="_blank"
          rel="noopener noreferrer"
        >
          <GitHubLogoIcon className="w-5 h-5" />
        </a>
        <a
          href="https://www.linkedin.com/in/lars-ullrich-47338a27/"
          aria-label="LinkedIn"
          className="text-white/70 hover:text-white transition-colors cursor-pointer h-12 w-12 flex items-center justify-center"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedInLogoIcon className="w-5 h-5" />
        </a>
        <a
          href="https://www.facebook.com/lars.ullrich.75"
          aria-label="Facebook"
          className="text-white/70 hover:text-white transition-colors cursor-pointer h-12 w-12 flex items-center justify-center"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            className="w-5 h-5"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.19795 21.5H13.198V13.4901H16.8021L17.198 9.50977H13.198V7.5C13.198 6.94772 13.6457 6.5 14.198 6.5H17.198V2.5H14.198C11.4365 2.5 9.19795 4.73858 9.19795 7.5V9.50977H7.19795L6.80206 13.4901H9.19795V21.5Z"
              fill="currentColor"
            />
          </svg>
        </a>
        <a
          href="https://www.instagram.com/vllrich/"
          aria-label="Instagram"
          className="text-white/70 hover:text-white transition-colors cursor-pointer h-12 w-12 flex items-center justify-center"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            className="w-5 h-5"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7ZM9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12Z"
              fill="currentColor"
            />
            <path
              d="M18 5C17.4477 5 17 5.44772 17 6C17 6.55228 17.4477 7 18 7C18.5523 7 19 6.55228 19 6C19 5.44772 18.5523 5 18 5Z"
              fill="currentColor"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5 1C2.79086 1 1 2.79086 1 5V19C1 21.2091 2.79086 23 5 23H19C21.2091 23 23 21.2091 23 19V5C23 2.79086 21.2091 1 19 1H5ZM19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
              fill="currentColor"
            />
          </svg>
        </a>
      </div>

      {/* Mobile menu overlay - only visible when menu is open */}
      {isMobileMenuOpen && (
        <div className="fixed inset-0 bg-black/90 z-40 flex flex-col items-center justify-center sm:hidden">
          <div className="flex flex-col items-center space-y-8">
            <button
              onClick={() => {
                setIsContactModalOpen(true);
                setIsMobileMenuOpen(false);
              }}
              className="text-white hover:text-[#c0ff4d] transition-colors text-2xl uppercase tracking-widest py-4"
            >
              Contact
            </button>
            <button
              onClick={() => setIsMobileMenuOpen(false)}
              className="mt-8 text-white/70 hover:text-white border border-white/20 px-6 py-3 rounded-md"
            >
              Close Menu
            </button>
          </div>
        </div>
      )}

      {/* Contact Modal */}
      <ContactModal
        isOpen={isContactModalOpen}
        onClose={() => setIsContactModalOpen(false)}
      />
    </div>
  );
};

export default TopNavbar;
