import React from "react";
import { PlusIcon } from "./icons/PlusIcon";

/**
 * Reusable Modal Component with customizable appearance
 *
 * @param {boolean} isOpen - Whether the modal is currently open
 * @param {Function} onClose - Function to call when the modal is closed
 * @param {string} title - The title displayed in the modal header
 * @param {React.ReactNode} children - Content to render inside the modal
 * @param {'small'|'default'} size - Size variant for the modal
 * @param {string} customClassName - Optional custom class for the modal container
 */
export const Modal = ({
  isOpen,
  onClose,
  title,
  children,
  size = "default",
  customClassName = "",
}) => {
  if (!isOpen) return null;

  // Calculate width class based on size
  const sizeClasses = {
    small: "max-w-md", // 75% of default (3xl)
    default: "max-w-3xl",
  };

  const widthClass = sizeClasses[size] || sizeClasses.default;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      {/* Backdrop - using background with opacity and blur */}
      <div
        className="fixed inset-0 bg-background/70 backdrop-blur-sm"
        onClick={onClose}
      />

      {/* Modal Content */}
      <div className={`relative z-10 w-full ${widthClass} flex items-center`}>
        <div
          className={`relative w-full ${
            customClassName ||
            "border border-dashed border-border bg-card rounded-md"
          }`}
        >
          {customClassName ? null : (
            <>
              {/* Corner elements */}
              <PlusIcon className="-top-3 -left-3" />
              <PlusIcon className="-top-3 -right-3" />
              <PlusIcon className="-bottom-3 -left-3" />
              <PlusIcon className="-bottom-3 -right-3" />

              {/* Header */}
              <div className="flex items-center justify-between p-4 border-b border-dashed border-border">
                <h2 className="text-lg font-medium text-foreground">{title}</h2>
                <button
                  onClick={onClose}
                  className="p-1 rounded-full text-muted-foreground hover:text-primary transition-colors"
                  aria-label="Close modal"
                >
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                  >
                    <path
                      d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z"
                      fill="currentColor"
                      fillRule="evenodd"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </>
          )}

          {/* Body */}
          <div className={customClassName ? "" : "p-6 text-sm"}>{children}</div>
        </div>
      </div>
    </div>
  );
};
