import { useState, createContext, useContext, useEffect } from "react";
import { motion } from "framer-motion";
import { cn } from "../lib/utils";
import { useNavigate, useLocation } from "react-router-dom";

// Create navbar context to expose the active navbar index to shaders
export const NavbarContext = createContext({
  activeIndex: 0,
  setActiveIndex: () => {},
});

// Hook to access navbar data
export const useBottomNavbar = () => useContext(NavbarContext);

// Menu items with proper routing paths
const MENU_ITEMS = [
  { label: "Home", href: "/" },
  { label: "About", href: "/about" },
  { label: "Gallery", href: "/gallery" },
  { label: "Projects", href: "/projects" },
];

// Navbar Provider component to wrap the application
export const NavbarProvider = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <NavbarContext.Provider value={{ activeIndex, setActiveIndex }}>
      {children}
    </NavbarContext.Provider>
  );
};

// Bottom Navbar component
const BottomNavbar = () => {
  const [activeItem, setActiveItem] = useState("Home");
  const { activeIndex, setActiveIndex } = useBottomNavbar();
  const navigate = useNavigate();
  const location = useLocation();
  // Add pending state to track navigation in progress
  const [pendingIndex, setPendingIndex] = useState(null);

  // Set active item based on current route on mount and route changes
  useEffect(() => {
    const currentPath = location.pathname;
    const matchingItem = MENU_ITEMS.find((item) => {
      // Check for exact match or if it's the home page
      if (item.href === currentPath) return true;
      // Special case for home page to match root path
      if (item.href === "/" && currentPath === "/") return true;
      return false;
    });

    if (matchingItem) {
      const newIndex = MENU_ITEMS.findIndex(
        (item) => item.label === matchingItem.label
      );
      setActiveItem(matchingItem.label);
      setActiveIndex(newIndex);
    }
  }, [location.pathname, setActiveIndex]);

  // Handle menu item click
  const handleItemClick = (label, index) => {
    // Set the active item immediately for visual feedback
    setActiveItem(label);
    // Set pending index but don't update context yet
    setPendingIndex(index);

    // Navigate to the appropriate page
    const item = MENU_ITEMS.find((item) => item.label === label);
    if (item && item.href) {
      navigate(item.href);
      
      // Delay updating the activeIndex to allow content to load and animate first
      // Use a slightly longer delay to ensure content has time to animate
      setTimeout(() => {
        setActiveIndex(index);
        setPendingIndex(null);
      }, 500); // Increased to 500ms to give content more time to animate
    }
  };

  return (
    <nav className="sticky bottom-0 left-0 w-full z-50 bg-white/10 backdrop-blur-md border-t border-white/10">
      <div className="max-w-screen-xl mx-auto px-2 sm:px-4">
        <div className="flex items-center justify-between py-3 md:py-4">
          {/* Menu Items */}
          <div className="leading-tight font-khinterference font-light tracking-widest flex items-center justify-around w-full">
            {MENU_ITEMS.map((item, index) => (
              <a
                key={item.label}
                href={item.href}
                className={cn(
                  "relative flex items-center justify-center h-12 min-w-[3rem] px-2 sm:px-4 md:px-6 py-2 transition-colors duration-200 text-xs sm:text-sm md:text-base",
                  activeItem === item.label
                    ? "text-[#ffffff]"
                    : "text-[#e8e8e8] hover:text-gray-900"
                )}
                onClick={(e) => {
                  e.preventDefault();
                  handleItemClick(item.label, index);
                }}
              >
                {item.label}
                {activeItem === item.label && (
                  <motion.div
                    className="absolute bottom-0 left-0 w-full h-0.5 bg-[#e6e6e6]"
                    layoutId="bottom-navbar-indicator"
                  />
                )}
              </a>
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
};

export { BottomNavbar };
