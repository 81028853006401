import { useState, useEffect, useRef } from "react";

export default function ScrambleHover({
  text,
  scrambleSpeed = 50,
  maxIterations = 8,
  useOriginalCharsOnly = true,
  characters = "",
  className = "",
}) {
  const [isHovering, setIsHovering] = useState(false);
  const originalChars = useRef(text.split(""));
  const [displayText, setDisplayText] = useState(text);
  const iterationCount = useRef(0);
  const intervalRef = useRef(null);

  // Get all possible characters for scrambling
  const allPossibleChars = useOriginalCharsOnly
    ? originalChars.current
    : characters
    ? characters.split("")
    : "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_-+=[]{}|;:'\",./<>?".split(
        ""
      );

  useEffect(() => {
    if (isHovering) {
      intervalRef.current = setInterval(() => {
        if (iterationCount.current >= maxIterations) {
          // Reset to original text after max iterations
          setDisplayText(text);
          clearInterval(intervalRef.current);
          iterationCount.current = 0;
          return;
        }

        // Create a scrambled version
        const scrambled = originalChars.current.map((char, idx) => {
          // Don't scramble spaces
          if (char === " ") return " ";
          // Randomly decide if this character should be scrambled
          return Math.random() > 0.5
            ? allPossibleChars[
                Math.floor(Math.random() * allPossibleChars.length)
              ]
            : char;
        });

        setDisplayText(scrambled.join(""));
        iterationCount.current += 1;
      }, scrambleSpeed);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        setDisplayText(text); // Reset to original when unmounting
      }
    };
  }, [isHovering, text, scrambleSpeed, maxIterations, allPossibleChars]);

  return (
    <span
      className={className}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => {
        setIsHovering(false);
        clearInterval(intervalRef.current);
        setDisplayText(text);
        iterationCount.current = 0;
      }}
    >
      {displayText}
    </span>
  );
}
