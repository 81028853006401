"use client";

import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronLeft, ChevronRight, X } from "lucide-react";
import { allProjects } from "./data/projects.js?v=2";
import ScrambleHover from "@/fancy/components/text/scramble-hover";
import { useMousePosition } from "../../hooks/useMousePosition";

const Gallery = () => {
  // State for expandable card
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectImageIndices, setProjectImageIndices] = useState({});
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [hoveredProject, setHoveredProject] = useState(null);
  const galleryRef = useRef(null);
  const { mousePosition, handleMouseMove } = useMousePosition();

  // Virtualization state
  const [visibleRange, setVisibleRange] = useState({ start: 0, end: 20 });
  const observerRef = useRef(null);
  const lastItemRef = useRef(null);

  // Function to handle project click and expand the card
  const handleProjectClick = useCallback(
    (project) => {
      if (selectedProject && selectedProject.id === project.id) {
        // If clicking the same project, close it
        setSelectedProject(null);
      } else {
        // Otherwise, open the selected project and reset image index
        setSelectedProject({ ...project });
        // Initialize image index if not already set
        if (!projectImageIndices[project.id]) {
          setProjectImageIndices((prev) => ({
            ...prev,
            [project.id]: 0,
          }));
        }
      }
    },
    [selectedProject, projectImageIndices]
  );

  // Function to close the card
  const closeCard = useCallback(() => {
    setSelectedProject(null);
  }, []);

  // Memoized projects sliced by visible range
  const visibleProjects = useMemo(() => {
    // Always show at least the first 10 projects
    const end = Math.max(10, visibleRange.end);
    return allProjects.slice(0, end);
  }, [visibleRange.end]);

  // Setup intersection observer for infinite loading
  useEffect(() => {
    if (lastItemRef.current && visibleRange.end < allProjects.length) {
      observerRef.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            // When last item is visible, load more items
            setVisibleRange((prev) => ({
              ...prev,
              end: Math.min(prev.end + 10, allProjects.length),
            }));
          }
        },
        { rootMargin: "200px" }
      );

      observerRef.current.observe(lastItemRef.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [visibleRange, visibleProjects]);

  // Handle image navigation for a specific project
  const handleImageNav = useCallback(
    (projectId, direction) => {
      if (isTransitioning) return;

      const project = allProjects.find((p) => p.id === projectId);
      if (!project) return;

      const images = project.images || [project.image];
      if (images.length <= 1) return;

      setIsTransitioning(true);

      setProjectImageIndices((prev) => {
        const currentIndex = prev[projectId] || 0;
        const newIndex =
          direction === "next"
            ? (currentIndex + 1) % images.length
            : (currentIndex - 1 + images.length) % images.length;

        return {
          ...prev,
          [projectId]: newIndex,
        };
      });

      setTimeout(() => setIsTransitioning(false), 400);
    },
    [isTransitioning]
  );

  // Floating image for hover preview
  const hoverImage = useMemo(() => {
    if (!hoveredProject || !hoveredProject.image || selectedProject)
      return null;

    return (
      <motion.div
        className="fixed pointer-events-none z-10"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{
          opacity: 1,
          scale: 1,
        }}
        exit={{ opacity: 0, scale: 0.9 }}
        transition={{
          type: "spring",
          stiffness: 300,
          damping: 20,
          mass: 0.5,
        }}
        style={{
          position: "fixed",
          left: `${mousePosition.x}px`,
          top: `${mousePosition.y}px`,
          maxWidth: "300px",
          maxHeight: "200px",
          overflow: "hidden",
          borderRadius: "4px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.4)",
          transform: "translate(-50%, -50%)",
        }}
      >
        <motion.div
          className="w-full h-full"
          initial={{ scale: 1.1 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.4 }}
          style={{
            backgroundImage: `url(${hoveredProject.image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "300px",
            height: "200px",
          }}
        />
        <div className="absolute inset-0 border border-[#c0ff4d]/30"></div>

        {/* Corner brackets with animated entry */}
        <motion.div
          initial={{ width: 0, opacity: 0 }}
          animate={{ width: "12px", opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="absolute top-0 left-0 h-3 border-t border-l border-[#c0ff4d]"
        />
        <motion.div
          initial={{ width: 0, opacity: 0 }}
          animate={{ width: "12px", opacity: 1 }}
          transition={{ delay: 0.3 }}
          className="absolute top-0 right-0 h-3 border-t border-r border-[#c0ff4d]"
        />
        <motion.div
          initial={{ width: 0, opacity: 0 }}
          animate={{ width: "12px", opacity: 1 }}
          transition={{ delay: 0.4 }}
          className="absolute bottom-0 left-0 h-3 border-b border-l border-[#c0ff4d]"
        />
        <motion.div
          initial={{ width: 0, opacity: 0 }}
          animate={{ width: "12px", opacity: 1 }}
          transition={{ delay: 0.5 }}
          className="absolute bottom-0 right-0 h-3 border-b border-r border-[#c0ff4d]"
        />
      </motion.div>
    );
  }, [hoveredProject, mousePosition.x, mousePosition.y, selectedProject]);

  return (
    <div
      className="w-full min-h-screen relative z-20 flex flex-col font-['JetBrains_Mono'] pt-16"
      ref={galleryRef}
      onMouseMove={handleMouseMove}
    >
      {/* Section title header with animated highlight */}
      <div className="container mx-auto px-2 sm:px-4 md:px-6 lg:px-8 py-8 sm:py-12 md:py-16 lg:py-20">
        <div className="relative w-full mb-8 sm:mb-12 md:mb-16">
          <h2 className="font-khinterference text-3xl sm:text-4xl md:text-5xl lg:text-7xl font-light uppercase relative">
            <ScrambleHover
              text="Gallery"
              scrambleSpeed={50}
              maxIterations={8}
              useOriginalCharsOnly={false}
              characters="ABCDEFGHIJKLMNOPQRSTUVWXYZ"
              className="cursor-pointer"
            />
            <motion.span
              className="absolute -bottom-1 left-0 h-[2px] bg-[#c0ff4d]/70"
              initial={{ width: 0 }}
              animate={{ width: "100%" }}
              transition={{ duration: 1.5, delay: 0.5, ease: "easeInOut" }}
            />
          </h2>
          <p className="text-xs sm:text-sm uppercase leading-relaxed text-white/80 max-w-3xl mt-2 sm:mt-4">
            A selection of computational art, interfaces, and experiences
            created at the intersection of design and technology.
          </p>
        </div>

        {/* Minimal Project List - Virtualized */}
        <div className="space-y-6 sm:space-y-8 md:space-y-12">
          {visibleProjects.map((project, idx) => {
            const isLastItem =
              idx === visibleProjects.length - 1 &&
              idx < allProjects.length - 1;
            const isSelected =
              selectedProject && selectedProject.id === project.id;
            const currentImageIndex = projectImageIndices[project.id] || 0;

            return (
              <motion.div
                key={project.id}
                ref={isLastItem ? lastItemRef : null}
                className="pb-4 sm:pb-6"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.4, delay: Math.min(0.1 * idx, 1) }}
                onMouseEnter={() =>
                  !selectedProject && setHoveredProject(project)
                }
                onMouseLeave={() => !selectedProject && setHoveredProject(null)}
              >
                <div
                  className={`flex flex-col sm:flex-row sm:items-baseline gap-2 sm:gap-4 md:gap-6 cursor-pointer group ${
                    isSelected ? "bg-black/40" : "bg-white/5"
                  } p-3 sm:p-4 rounded-md hover:bg-black/40 transition-colors`}
                  onClick={() => handleProjectClick(project)}
                >
                  <span className="text-[#c0ff4d] font-medium min-w-[1.5rem] sm:w-6 text-base sm:text-lg">
                    {(idx + 1).toString().padStart(2, "0")}
                  </span>
                  <div className="flex-1">
                    <div className="flex items-center">
                      <h3
                        className={`font-khinterference text-white text-xl sm:text-2xl md:text-3xl font-light uppercase tracking-wide ${
                          isSelected
                            ? "text-[#c0ff4d]"
                            : "group-hover:text-[#c0ff4d]"
                        } transition-colors`}
                      >
                        <ScrambleHover
                          text={project.title}
                          scrambleSpeed={50}
                          maxIterations={5}
                          useOriginalCharsOnly={true}
                          className="cursor-pointer"
                        />
                      </h3>
                      <motion.span
                        className="ml-2 sm:ml-3 text-[#c0ff4d] opacity-0 group-hover:opacity-100"
                        animate={{ x: [0, 5, 0] }}
                        transition={{
                          duration: 1.5,
                          repeat: Infinity,
                          ease: "easeInOut",
                        }}
                      >
                        {isSelected ? "↓" : "→"}
                      </motion.span>
                    </div>
                    <p className="text-white/70 text-xs sm:text-sm mt-1 sm:mt-2 uppercase">
                      {project.label}
                    </p>
                    <p className="text-white/90 text-xs mt-2 sm:mt-3 max-w-2xl line-clamp-2 sm:line-clamp-none">
                      {(project.summary &&
                        project.summary.split(".")[0] + "...") ||
                        "Interactive computational art exploring the boundaries of digital creativity..."}
                    </p>

                    {project.credits && project.credits.length > 0 && (
                      <div className="flex flex-wrap gap-2 mt-3 sm:mt-4">
                        {project.credits.slice(0, 3).map((credit, index) => (
                          <motion.span
                            key={index}
                            className="inline-block px-2 py-1 border border-[#c0ff4d]/30 bg-transparent text-[#c0ff4d] text-[10px] uppercase"
                            whileHover={{
                              backgroundColor: "rgba(192, 255, 77, 0.1)",
                              y: -2,
                            }}
                            transition={{ duration: 0.2 }}
                          >
                            {credit.role}
                          </motion.span>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                {/* Expandable Project Card - shows when clicked */}
                <AnimatePresence>
                  {isSelected && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{
                        duration: 0.4,
                        height: { type: "spring", stiffness: 300, damping: 30 },
                      }}
                      className="w-full overflow-hidden"
                    >
                      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6 bg-black/40 p-3 sm:p-4 border-l-2 border-[#c0ff4d]/40 mt-2 backdrop-blur-sm relative">
                        {/* Decorative elements */}
                        <div className="absolute top-0 right-0 w-24 sm:w-32 h-24 sm:h-32 bg-[#c0ff4d]/5 rounded-full blur-3xl -z-10"></div>
                        <div className="absolute bottom-0 left-0 w-16 sm:w-24 h-16 sm:h-24 bg-[#c0ff4d]/10 rounded-full blur-2xl -z-10"></div>
                        <div className="absolute top-0 left-4 h-[1px] w-12 bg-gradient-to-r from-transparent to-[#c0ff4d]/40"></div>

                        {/* Project details */}
                        <div className="space-y-4 text-white/95 pr-2 sm:pr-4 flex flex-col order-2 lg:order-1">
                          <div className="flex items-start gap-3 sm:gap-6">
                            <div className="hidden sm:flex w-6 flex-col items-center justify-start">
                              <motion.div
                                className="h-3 w-3 rounded-full bg-[#c0ff4d]/30 mb-2"
                                animate={{
                                  opacity: [0.3, 0.6, 0.3],
                                  scale: [1, 1.1, 1],
                                }}
                                transition={{
                                  duration: 4,
                                  repeat: Infinity,
                                  ease: "easeInOut",
                                }}
                              />
                              <div className="h-24 w-[1px] bg-gradient-to-b from-[#c0ff4d]/10 via-[#c0ff4d]/40 to-transparent"></div>
                            </div>

                            <div className="flex-1">
                              <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-2">
                                <div className="flex gap-2 sm:gap-3 text-[10px] sm:text-xs text-white/60 mb-2 sm:mb-0">
                                  <span className="py-1 px-2 bg-black/30 border border-white/10 rounded-sm">
                                    {project.year}
                                  </span>
                                  <span className="py-1 px-2 bg-black/30 border border-white/10 rounded-sm">
                                    {project.type || "Project"}
                                  </span>
                                </div>
                                <button
                                  className="text-white/50 hover:text-[#c0ff4d] transition-colors h-10 w-10 flex items-center justify-center ml-auto sm:ml-0"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    closeCard();
                                  }}
                                  aria-label="Close details"
                                >
                                  <X size={18} />
                                </button>
                              </div>

                              <div className="text-xs sm:text-sm leading-relaxed pr-0 sm:pr-2 max-h-[40vh] sm:max-h-[60vh] overflow-y-auto custom-scrollbar">
                                {project.details}

                                {/* Credits section */}
                                {project.credits &&
                                  project.credits.length > 0 && (
                                    <div className="pt-4 sm:pt-6 mt-3 sm:mt-4 border-t border-white/10">
                                      <h5 className="text-xs uppercase text-[#c0ff4d]/70 mb-2 sm:mb-3 tracking-wider">
                                        Credits
                                      </h5>
                                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-3">
                                        {project.credits.map(
                                          (credit, index) => (
                                            <div
                                              key={index}
                                              className="text-xs flex items-center group"
                                            >
                                              <span className="inline-block w-2 h-2 bg-[#c0ff4d]/30 mr-2 group-hover:bg-[#c0ff4d]/70 transition-colors"></span>
                                              <span className="text-[#c0ff4d]/80">
                                                {credit.role}:{" "}
                                              </span>
                                              <span className="text-white/80 ml-1">
                                                {credit.name}
                                              </span>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Image slider */}
                        <div className="relative overflow-hidden group rounded-sm bg-black/40 order-1 lg:order-2 min-h-[200px] sm:min-h-[250px] md:min-h-[300px]">
                          {/* Image navigation buttons */}
                          {project.images && project.images.length > 1 && (
                            <>
                              <button
                                className="absolute left-3 top-1/2 transform -translate-y-1/2 bg-black/60 p-2 rounded-full z-10 text-white/60 hover:text-[#c0ff4d] hover:bg-black/80 transition-colors opacity-70 sm:opacity-0 group-hover:opacity-100"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleImageNav(project.id, "prev");
                                }}
                                aria-label="Previous image"
                              >
                                <ChevronLeft size={18} />
                              </button>
                              <button
                                className="absolute right-3 top-1/2 transform -translate-y-1/2 bg-black/60 p-2 rounded-full z-10 text-white/60 hover:text-[#c0ff4d] hover:bg-black/80 transition-colors opacity-70 sm:opacity-0 group-hover:opacity-100"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleImageNav(project.id, "next");
                                }}
                                aria-label="Next image"
                              >
                                <ChevronRight size={18} />
                              </button>
                            </>
                          )}

                          {/* Image display */}
                          <div className="relative w-full h-full aspect-[4/3] lg:aspect-[4/3]">
                            {/* Image counter */}
                            {project.images && project.images.length > 1 && (
                              <div className="absolute bottom-3 right-3 bg-black/70 backdrop-blur-sm px-2 py-1 text-xs text-white/70 rounded-sm z-10 border border-white/10">
                                {currentImageIndex + 1}/{project.images.length}
                              </div>
                            )}

                            <AnimatePresence mode="wait">
                              <motion.div
                                key={`${project.id}-img-${currentImageIndex}`}
                                className="w-full h-full"
                                initial={{ opacity: 0, scale: 1.05 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.4 }}
                              >
                                <img
                                  src={
                                    project.images
                                      ? project.images[currentImageIndex]
                                      : project.image
                                  }
                                  alt={`${project.title} - image ${
                                    currentImageIndex + 1
                                  }`}
                                  className="object-cover w-full h-full"
                                  loading="lazy"
                                />

                                {/* Image overlay with subtle gradient */}
                                <div className="absolute inset-0 bg-gradient-to-t from-black/40 to-transparent pointer-events-none"></div>

                                {/* Corner brackets */}
                                <div className="absolute top-0 left-0 w-3 sm:w-4 h-3 sm:h-4 border-t border-l border-[#c0ff4d]/60"></div>
                                <div className="absolute top-0 right-0 w-3 sm:w-4 h-3 sm:h-4 border-t border-r border-[#c0ff4d]/60"></div>
                                <div className="absolute bottom-0 left-0 w-3 sm:w-4 h-3 sm:h-4 border-b border-l border-[#c0ff4d]/60"></div>
                                <div className="absolute bottom-0 right-0 w-3 sm:w-4 h-3 sm:h-4 border-b border-r border-[#c0ff4d]/60"></div>
                              </motion.div>
                            </AnimatePresence>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            );
          })}

          {/* Loading indicator at the end if there are more projects */}
          {visibleRange.end < allProjects.length && (
            <div className="py-6 sm:py-8 flex justify-center">
              <div className="w-6 h-6 sm:w-8 sm:h-8 rounded-full border-2 border-[#c0ff4d]/30 border-t-[#c0ff4d] animate-spin"></div>
            </div>
          )}
        </div>
      </div>

      {/* Floating image on hover - Memoized to prevent rerenders - only show on desktop/tablet */}
      <AnimatePresence>{!isMobile() && hoverImage}</AnimatePresence>

      {/* Terminal footer with typing animation */}
      <div className="container mx-auto px-2 sm:px-4 sm:px-8 md:px-12 lg:px-16 py-4 sm:py-8 mt-auto">
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center text-white/50 text-xs gap-2 sm:gap-0">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            Gallery:// {allProjects.length} projects loaded
          </motion.div>
          <div className="flex items-center gap-3">
            <div className="flex space-x-2 items-center">
              <motion.div
                className="w-2 h-2 rounded-full bg-[#c0ff4d]"
                animate={{
                  opacity: [1, 0.5, 1],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              ></motion.div>
            </div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.7 }}
              className="hidden sm:block"
            >
              hover to preview // click to expand details
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.7 }}
              className="sm:hidden"
            >
              tap to expand details
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Add helper function to detect mobile devices
const isMobile = () => {
  if (typeof window === 'undefined') return false;
  return window.innerWidth < 768 || 
    (typeof window.orientation !== 'undefined') || 
    (navigator.userAgent.indexOf('IEMobile') !== -1);
};

export default Gallery;
