import { Component } from "react";

/**
 * Error Boundary component that catches JavaScript errors anywhere in the child component tree,
 * logs those errors, and displays a fallback UI instead of the component tree that crashed.
 */
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        this.props.fallback || (
          <div className="flex flex-col items-center justify-center min-h-screen p-4 text-center bg-background">
            <div className="max-w-md">
              <h2 className="text-2xl font-bold text-foreground mb-4">
                Something went wrong
              </h2>
              <p className="text-muted-foreground mb-6">
                The application encountered an unexpected error. Try refreshing
                the page.
              </p>
              {this.state.error && (
                <div className="mt-4 p-4 bg-background/10 rounded-md border border-border text-left overflow-auto">
                  <p className="text-sm text-foreground/70 mb-2">
                    {this.state.error.toString()}
                  </p>
                  {this.state.errorInfo && (
                    <details className="text-xs text-foreground/60">
                      <summary className="cursor-pointer">
                        Component Stack
                      </summary>
                      <pre>{this.state.errorInfo.componentStack}</pre>
                    </details>
                  )}
                </div>
              )}
              <button
                className="mt-6 px-4 py-2 bg-primary hover:bg-primary/90 text-primary-foreground rounded-md"
                onClick={() => window.location.reload()}
              >
                Reload Page
              </button>
            </div>
          </div>
        )
      );
    }

    // If there's no error, render children normally
    return this.props.children;
  }
}

export default ErrorBoundary;
