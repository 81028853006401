import React, { useState } from "react";
import { Modal } from "../ui/Modal";
import { GitHubLogoIcon, LinkedInLogoIcon } from "@radix-ui/react-icons";

/**
 * Contact form component with service selection checkboxes
 *
 * @param {Function} onClose - Function to close the parent modal
 */
const ContactForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    projectDescription: "",
    services: {
      webDesign: true,
      contentCreation: false,
      uxDesign: true,
      strategyConsulting: false,
      userResearch: false,
      other: false,
    },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      services: {
        ...formData.services,
        [name]: checked,
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form inputs
    if (!formData.name.trim() || !formData.email.trim()) {
      // Simple validation - could be enhanced
      alert("Please fill out all required fields");
      return;
    }

    // Add CSRF token if available
    const formDataToSubmit = {
      ...formData,
      // csrfToken: document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')
    };

    console.log("Form submitted:", formDataToSubmit);

    // TODO: Submit form data to backend API
    // fetch('/api/contact', {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify(formDataToSubmit)
    // })

    onClose();
  };

  return (
    <div className="bg-[#ffffff] text-[#0a0a16] rounded-2xl overflow-hidden p-8">
      <h2 className="text-3xl font-khinterference font-light tracking-wide mb-4">
        Let's Connect
      </h2>
      <p className="mb-6">
        Tell us more about yourself and what you've got in mind.
      </p>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            placeholder="Your name"
            className="w-full border-b-2 border-[#0a0a16] bg-transparent p-2 focus:outline-none"
            required
            aria-label="Your name"
          />
        </div>

        <div>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="you@company.com"
            className="w-full border-b-2 border-[#0a0a16] bg-transparent p-2 focus:outline-none"
            required
            aria-label="Your email address"
          />
        </div>

        <div>
          <input
            type="text"
            name="projectDescription"
            value={formData.projectDescription}
            onChange={handleInputChange}
            placeholder="Tell us a little about the project..."
            className="w-full border-b-2 border-[#0a0a16] bg-transparent p-2 focus:outline-none"
            required
            aria-label="Project description"
          />
        </div>

        <button
          type="submit"
          className="w-full py-3 bg-[#0a0a16] text-white rounded-md font-khinterference font-light tracking-wide hover:bg-[#1a1a2e] transition-colors"
        >
          Let's get started!
        </button>
      </form>

      <div className="flex justify-end mt-4">
        <div className="flex space-x-4">
          <a
            href="https://github.com/Vllrich"
            aria-label="GitHub"
            className="text-[#0a0a16] hover:opacity-70 transition-opacity"
            target="_blank"
            rel="noopener noreferrer"
          >
            <GitHubLogoIcon className="w-5 h-5" />
          </a>
          <a
            href="https://www.linkedin.com/in/lars-ullrich-47338a27/"
            aria-label="LinkedIn"
            className="text-[#0a0a16] hover:opacity-70 transition-opacity"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInLogoIcon className="w-5 h-5" />
          </a>
          <a
            href="https://www.facebook.com/lars.ullrich.75"
            aria-label="Facebook"
            className="text-[#0a0a16] hover:opacity-70 transition-opacity"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              className="w-5 h-5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.19795 21.5H13.198V13.4901H16.8021L17.198 9.50977H13.198V7.5C13.198 6.94772 13.6457 6.5 14.198 6.5H17.198V2.5H14.198C11.4365 2.5 9.19795 4.73858 9.19795 7.5V9.50977H7.19795L6.80206 13.4901H9.19795V21.5Z"
                fill="currentColor"
              />
            </svg>
          </a>
          <a
            href="https://www.instagram.com/vllrich/"
            aria-label="Instagram"
            className="text-[#0a0a16] hover:opacity-70 transition-opacity"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              className="w-5 h-5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7ZM9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12Z"
                fill="currentColor"
              />
              <path
                d="M18 5C17.4477 5 17 5.44772 17 6C17 6.55228 17.4477 7 18 7C18.5523 7 19 6.55228 19 6C19 5.44772 18.5523 5 18 5Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 1C2.79086 1 1 2.79086 1 5V19C1 21.2091 2.79086 23 5 23H19C21.2091 23 23 21.2091 23 19V5C23 2.79086 21.2091 1 19 1H5ZM19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
                fill="currentColor"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

/**
 * Modal component containing the contact form
 *
 * @param {boolean} isOpen - Whether the modal is open
 * @param {Function} onClose - Function to close the modal
 */
export const ContactModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="small"
      customClassName="rounded-2xl overflow-hidden"
    >
      <ContactForm onClose={onClose} />
    </Modal>
  );
};
