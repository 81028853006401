import React from 'react';

/**
 * Plus icon component used for industrial corner elements
 * @param {Object} props - Component props
 * @param {string} props.className - Additional CSS classes
 */
export const PlusIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    strokeWidth="1"
    stroke="currentColor"
    className={`text-primary size-6 absolute ${className}`}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
  </svg>
); 