import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import {
  exhibitionData,
  installationData,
  collaborationData,
  theaterData,
} from "./data/projectData";
import ScrambleHover from "@/fancy/components/text/scramble-hover";
import { useMousePosition } from "@/hooks/useMousePosition";

// Redesigned project list component to match Gallery.jsx style
const ProjectList = ({ data, sortByDate = false }) => {
  const { mousePosition, handleMouseMove } = useMousePosition();
  const [hoveredItem, setHoveredItem] = useState(null);

  // Sort data if needed
  const sortedData = sortByDate
    ? [...data].sort((a, b) => {
        // Handle cases where date is missing, null, or 'N/A'
        if (!a.date || a.date === "N/A") return 1;
        if (!b.date || b.date === "N/A") return -1;

        // Handle different date formats by converting to Date objects when possible
        try {
          const dateA = new Date(a.date.split("/").reverse().join("/"));
          const dateB = new Date(b.date.split("/").reverse().join("/"));
          return dateB - dateA; // newest first
        } catch (e) {
          // If there's an error parsing dates, safely compare strings
          return (b.date || "").localeCompare(a.date || "");
        }
      })
    : data;

  return (
    <motion.div
      className="space-y-3 sm:space-y-4 md:space-y-6"
      variants={{
        hidden: { opacity: 0 },
        visible: {
          opacity: 1,
          transition: { staggerChildren: 0.1 },
        },
      }}
      onMouseMove={handleMouseMove}
    >
      {sortedData.map((item, idx) => (
        <motion.div
          key={idx}
          className="pb-1 sm:pb-1"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4, delay: 0.1 * idx }}
          onMouseEnter={() => setHoveredItem(item)}
          onMouseLeave={() => setHoveredItem(null)}
        >
          <div className="flex flex-col sm:flex-row sm:items-baseline gap-2 sm:gap-4 md:gap-6 cursor-pointer group p-3 sm:p-4 rounded-md hover:bg-white/10 transition-colors">
            <span className="text-[#c0ff4d] font-medium min-w-[1.5rem] sm:w-6 text-base sm:text-lg font-['JetBrains_Mono']">
              {(idx + 1).toString().padStart(2, "0")}
            </span>
            <div className="flex-1">
              <div className="flex items-center">
                <h3 className="font-khinterference text-xl sm:text-2xl md:text-3xl font-light uppercase tracking-wide group-hover:text-[#c0ff4d] transition-colors">
                  <ScrambleHover
                    text={item.project || item.production || item.partner}
                    scrambleSpeed={50}
                    maxIterations={5}
                    useOriginalCharsOnly={true}
                    className="cursor-pointer"
                  />
                </h3>
                <motion.span
                  className="ml-2 sm:ml-3 text-[#c0ff4d] opacity-0 group-hover:opacity-100"
                  animate={{ x: [0, 5, 0] }}
                  transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    ease: "easeInOut",
                  }}
                >
                  →
                </motion.span>
              </div>

              <div className="flex flex-wrap items-center gap-2 sm:gap-4 mt-2">
                {item.date && item.date !== "N/A" && (
                  <p className="text-white/60 text-xs sm:text-sm uppercase font-['JetBrains_Mono']">
                    {item.date}
                  </p>
                )}

                {(item.location || item.venue) && (
                  <p className="text-white/60 text-xs sm:text-sm">
                    {item.location || item.venue}
                  </p>
                )}

                <div className="flex flex-wrap gap-2 mt-1 sm:mt-2">
                  {item.medium && (
                    <motion.span
                      className="inline-block px-2 py-1 border border-[#c0ff4d]/30 bg-transparent text-[#c0ff4d] text-[10px] uppercase font-['JetBrains_Mono']"
                      whileHover={{
                        backgroundColor: "rgba(192, 255, 77, 0.1)",
                        y: -2,
                      }}
                      transition={{ duration: 0.2 }}
                    >
                      {Array.isArray(item.medium) ? item.medium[0] : item.medium}
                    </motion.span>
                  )}
                  {item.type && (
                    <motion.span
                      className="inline-block px-2 py-1 border border-[#c0ff4d]/30 bg-transparent text-[#c0ff4d] text-[10px] uppercase font-['JetBrains_Mono']"
                      whileHover={{
                        backgroundColor: "rgba(192, 255, 77, 0.1)",
                        y: -2,
                      }}
                      transition={{ duration: 0.2 }}
                    >
                      {item.type}
                    </motion.span>
                  )}
                  {item.role && (
                    <motion.span
                      className="inline-block px-2 py-1 border border-[#c0ff4d]/30 bg-transparent text-[#c0ff4d] text-[10px] uppercase font-['JetBrains_Mono']"
                      whileHover={{
                        backgroundColor: "rgba(192, 255, 77, 0.1)",
                        y: -2,
                      }}
                      transition={{ duration: 0.2 }}
                    >
                      {item.role}
                    </motion.span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      ))}

      <AnimatePresence>
        {hoveredItem && hoveredItem.description && (
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.2 }}
            className="fixed z-50 max-w-[280px] sm:max-w-[320px] bg-black/90 border border-[#c0ff4d]/20 text-white text-sm p-3 rounded-md shadow-lg pointer-events-none"
            style={{
              left: `${mousePosition.x + 15}px`,
              top: `${mousePosition.y + 15}px`,
            }}
          >
            <p className="text-white/90">{hoveredItem.description}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const Projects = () => {
  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.1,
      },
    },
  };

  // Combine all project data for "All" tab
  const allProjects = [
    ...exhibitionData,
    ...installationData,
    ...collaborationData,
    ...theaterData,
  ];

  return (
    <motion.div
      className="w-full min-h-screen relative z-20 flex flex-col font-['JetBrains_Mono'] pt-16"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      {/* Section title header with animated highlight */}
      <div className="container mx-auto px-2 sm:px-4 md:px-6 lg:px-8 py-8 sm:py-12 md:py-16 lg:py-20">
        <div className="relative w-full mb-8 sm:mb-12 md:mb-16">
          <h2 className="font-khinterference text-3xl sm:text-4xl md:text-5xl lg:text-7xl font-light uppercase relative">
            <ScrambleHover
              text="Projects"
              scrambleSpeed={50}
              maxIterations={8}
              useOriginalCharsOnly={false}
              characters="ABCDEFGHIJKLMNOPQRSTUVWXYZ"
              className="cursor-pointer"
            />
            <motion.span
              className="absolute -bottom-1 left-0 h-[2px] bg-[#c0ff4d]/70"
              initial={{ width: 0 }}
              animate={{ width: "100%" }}
              transition={{ duration: 1.5, delay: 0.5, ease: "easeInOut" }}
            />
          </h2>
          <p className="text-xs sm:text-sm uppercase leading-relaxed text-white/80 max-w-3xl mt-2 sm:mt-4">
            A broader selection of some projects from recent years
          </p>
        </div>

        <Tabs defaultValue="all" className="w-full">
          <TabsList className="mb-4 sm:mb-8 flex flex-wrap justify-start h-auto bg-transparent gap-1 overflow-x-auto pb-2 max-w-[calc(100vw-2rem)]">
            <TabsTrigger
              value="all"
              className="min-h-[32px] px-3 py-1 rounded-md border border-[#c0ff4d]/30 data-[state=active]:bg-[#c0ff4d]/10 data-[state=active]:text-[#c0ff4d] text-white/80 text-[10px] sm:text-xs uppercase font-['JetBrains_Mono'] whitespace-nowrap"
            >
              All Projects
            </TabsTrigger>
            <TabsTrigger
              value="exhibitions"
              className="min-h-[32px] px-3 py-1 rounded-md border border-[#c0ff4d]/30 data-[state=active]:bg-[#c0ff4d]/10 data-[state=active]:text-[#c0ff4d] text-white/80 text-[10px] sm:text-xs uppercase font-['JetBrains_Mono'] whitespace-nowrap"
            >
              Exhibitions & Performances
            </TabsTrigger>
            <TabsTrigger
              value="installations"
              className="min-h-[32px] px-3 py-1 rounded-md border border-[#c0ff4d]/30 data-[state=active]:bg-[#c0ff4d]/10 data-[state=active]:text-[#c0ff4d] text-white/80 text-[10px] sm:text-xs uppercase font-['JetBrains_Mono'] whitespace-nowrap"
            >
              Installations
            </TabsTrigger>
            <TabsTrigger
              value="collaborations"
              className="min-h-[32px] px-3 py-1 rounded-md border border-[#c0ff4d]/30 data-[state=active]:bg-[#c0ff4d]/10 data-[state=active]:text-[#c0ff4d] text-white/80 text-[10px] sm:text-xs uppercase font-['JetBrains_Mono'] whitespace-nowrap"
            >
              Collaborations & Commercial
            </TabsTrigger>
            <TabsTrigger
              value="theater"
              className="min-h-[32px] px-3 py-1 rounded-md border border-[#c0ff4d]/30 data-[state=active]:bg-[#c0ff4d]/10 data-[state=active]:text-[#c0ff4d] text-white/80 text-[10px] sm:text-xs uppercase font-['JetBrains_Mono'] whitespace-nowrap"
            >
              Theater & Opera
            </TabsTrigger>
          </TabsList>

          {/* All Projects */}
          <TabsContent value="all" className="space-y-4">
            <ProjectList data={allProjects} sortByDate={true} />
          </TabsContent>

          {/* Exhibitions & Performances */}
          <TabsContent value="exhibitions" className="space-y-4">
            <ProjectList data={exhibitionData} sortByDate={true} />
          </TabsContent>

          {/* Installations */}
          <TabsContent value="installations" className="space-y-4">
            <ProjectList data={installationData} sortByDate={true} />
          </TabsContent>

          {/* Collaborations & Commercial */}
          <TabsContent value="collaborations" className="space-y-4">
            <ProjectList data={collaborationData} sortByDate={true} />
          </TabsContent>

          {/* Theater & Opera */}
          <TabsContent value="theater" className="space-y-4">
            <ProjectList data={theaterData} sortByDate={true} />
          </TabsContent>
        </Tabs>

        {/* Terminal footer with typing animation  */}
        <div className="py-4 sm:py-8 mt-8 sm:mt-16">
          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center text-white/50 text-xs gap-2 sm:gap-0">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.5 }}
            >
              Projects://{" "}
              {exhibitionData.length +
                installationData.length +
                collaborationData.length +
                theaterData.length}{" "}
              projects loaded
            </motion.div>
            <div className="flex items-center gap-3">
              <div className="flex space-x-2 items-center">
                <motion.div
                  className="w-2 h-2 rounded-full bg-[#c0ff4d]"
                  animate={{
                    opacity: [1, 0.5, 1],
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    ease: "easeInOut",
                  }}
                ></motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Projects;
