import React from "react";
import { motion } from "framer-motion";

const AboutPage = () => {
  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5, ease: "easeOut" },
    },
  };

  const fadeInUpVariants = {
    hidden: { y: 40, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };

  const cardHoverVariants = {
    hover: {
      y: -10,
      boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
      borderColor: "var(--primary)",
      transition: { duration: 0.3 },
    },
  };

  return (
    <div className="flex flex-col min-h-screen pt-16">
      {/* About Section */}
      <motion.section
        className="py-8 sm:py-12 md:py-16 lg:py-20 overflow-hidden"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="container mx-auto px-2 sm:px-4 md:px-6 lg:px-8">
          {/* Section title header with animated highlight */}
          <div className="relative w-full mb-8 sm:mb-12 md:mb-16">

            <h2 className="font-khinterference text-3xl sm:text-4xl md:text-5xl lg:text-7xl font-light uppercase relative">
              About
              <motion.span
                className="absolute -bottom-1 left-0 h-[2px] bg-primary/70"
                initial={{ width: 0 }}
                animate={{ width: "100%" }}
                transition={{ duration: 1.5, delay: 0.5, ease: "easeInOut" }}
              />
            </h2>
            <p className="text-xs sm:text-sm uppercase leading-relaxed text-white/80 max-w-3xl mt-2 sm:mt-4">
              Computational Design, Software Engineer, Technology Researcher
            </p>
          </div>

          <motion.div
            className="grid grid-cols-1 gap-5 lg:grid-cols-3"
            variants={containerVariants}
          >
            {/* Bio content - without card */}
            <motion.div
              className="lg:col-span-2 text-left"
              variants={itemVariants}
            >
              <div className="flex flex-col relative z-10 max-w-2xl">
                <p className="mb-4 sm:mb-6 text-sm sm:text-base leading-relaxed">
                  For over two decades, my work has transformed technological and scientific advancements into immersive human experiences, guided by both artistic intuition and technical precision.
                </p>
                <p className="mb-4 sm:mb-6 text-sm sm:text-base leading-relaxed">
                  This journey weaves through realms of interactive installations, performance systems, and computational art. My creative endeavors include video direction for Philip Glass and Robert Wilson's opera "Einstein on the Beach," immersive audiovisual installations for ECM Records' 50th anniversary, and custom software development for leading industry leaders. This practice has taken me to concert halls, galleries, and festivals across Europe and beyond, where technology serves as both medium and collaborator in creating meaningful artistic encounters.
                </p>
                <p className="mb-4 sm:mb-6 text-sm sm:text-base leading-relaxed">
                  As a full stack engineer, the approach involves architecting complete technological ecosystems that bridge hardware and software, frontend and backend systems. I develop high-performance computing solutions for complex visual data processing and responsive systems that adapt to user input in real time. The work frequently involves creating custom tools that transform raw data into meaningful interactive experiences, through visualization frameworks or responsive environments. This comprehensive technical approach enables me to implement complex projects from concept to completion, handling everything from low-level system architecture to intuitive user interfaces.
                </p>
                <p className="text-sm sm:text-base leading-relaxed">
                  Beyond artistic pursuits, I serve as a technical director for brands and startups, translating complex technological possibilities into strategic advantages. My ability to communicate effectively across departments—from product design and development to strategic planning—helps bridge gaps, foster collaboration, and drive innovation. Drawing from extensive experience with cutting-edge technologies across various industries, I strive to make meaningful contributions to every project undertaken. Current explorations focus on new territories at the intersection of human expression and machine learning, developing frameworks where technology amplifies artistic voice rather than replacing it—creating systems that respond to and evolve with human creativity in both sonic and visual domains.
                </p>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </motion.section>
    </div>
  );
};

export default AboutPage;
