import React from "react";
import { cn } from "@/lib/utils";

const Section = ({
  children,
  className,
  id,
  fullWidth = false,
  containerClassName,
  alignLeft = false,
  paddingTop = false,
}) => {
  return (
    <section id={id} className={cn("w-full py-0 md:py-0 pl-0 sm:pl-2 md:pl-[40px] lg:pl-[70px]", paddingTop && "pt-16 md:pt-24", className)}>
      <div
        className={cn(
          fullWidth ? "w-full" : "mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl",
          alignLeft && !fullWidth ? "ml-0 mr-auto" : fullWidth ? "" : "mx-auto",
          containerClassName
        )}
      >
        {children}
      </div>
    </section>
  );
};

export default Section;
