import React, { useRef, useState, useEffect } from "react";
import { motion, useInView, useAnimate, AnimatePresence } from "framer-motion";
import { debounce } from "lodash";

const Hero = () => {
  const heroRef = useRef(null);
  const [showSecondPart, setShowSecondPart] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isInGallery, setIsInGallery] = useState(false);

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5, ease: "easeOut" },
    },
  };

  const fadeInUpVariants = {
    hidden: { y: 40, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };

  const cardHoverVariants = {
    hover: {
      y: -10,
      boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
      borderColor: "var(--primary)",
      transition: { duration: 0.3 },
    },
  };

  const handleMouseMove = (event) => {
    if (!heroRef.current) return;

    const rect = heroRef.current.getBoundingClientRect();
    const y = event.clientY - rect.top; // y position within the element
    const height = rect.height;

    // If mouse moves past 50% of the screen height, show the second part
    const shouldShowSecond = y > height / 2;
    setShowSecondPart(shouldShowSecond);

    // Update mouse position for potential additional effects
    setMousePosition({ x: event.clientX - rect.left, y });
  };

  // Check if user has scrolled to gallery section
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      // Make hero visible as background in all sections
      // Only change the isInGallery state to control interactivity
      // The component will remain visible regardless
      setIsInGallery(scrollPosition > 720);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Add touch handler for mobile devices
  const handleTouchStart = () => {
    setShowSecondPart(!showSecondPart);
  };

  return (
    <div
      ref={heroRef}
      className={`w-full fixed top-0 left-0 right-0 overflow-hidden flex flex-col font-['JetBrains_Mono'] transition-all duration-500 hero-max-height ${
        isInGallery ? "opacity-40 blur-[1.5px]" : "opacity-100"
      }`}
      style={{
        height: "100vh",
        zIndex: -1,
      }}
      onMouseMove={!isInGallery ? handleMouseMove : undefined}
      onMouseLeave={() => !isInGallery && setShowSecondPart(false)}
      onTouchStart={!isInGallery ? handleTouchStart : undefined}
    >
      <div className="relative container mx-auto px-2 sm:px-4 md:px-6 lg:px-8 w-full h-full flex flex-col pt-16 md:pt-24 lg:pt-32">
        {/* Section indicator */}
        <div className="relative mb-4 w-full">

        </div>
        
        {/* Main content container with proper flex layout */}
        <div className="flex flex-col items-start w-full max-w-4xl space-y-2 md:space-y-3 lg:space-y-4 pl-8">
          {/* Phrases container with consistent positioning */}
          <div className="relative w-full min-h-[120px] md:min-h-[180px]">
            {/* First sentence */}
            <motion.div
              key="first-sentence"
              animate={{
                opacity: !showSecondPart ? 1 : 0,
                zIndex: !showSecondPart ? 2 : 1,
              }}
              transition={{ duration: 0.3 }}
              className="absolute top-0 left-0 w-full"
            >
              <div className="flex flex-col space-y-2 md:space-y-4">
                <h2 className="font-khinterference text-3xl sm:text-4xl md:text-5xl lg:text-7xl font-light tracking-wide w-full uppercase">
                  QUIETLY THINKING
                </h2>
                <h2 className="font-khinterference text-3xl sm:text-4xl md:text-5xl lg:text-7xl font-light tracking-wide w-full uppercase">
                  CAREFULLY MAKING
                </h2>
              </div>
            </motion.div>
          </div>

          {/* Description text with proper spacing */}
          <div className="w-full max-w-xs md:max-w-sm">
            <p className="text-xs sm:text-sm uppercase leading-relaxed text-white/80">
              Computational Artist, Software Engineer, Technology Researcher
            </p>
          </div>

          {/* Tags section */}
          <motion.div
            className="flex flex-wrap gap-2 justify-start mb-6 sm:mb-10 mt-2"
            variants={itemVariants}
          >
            {[
              "Creative Coding",
              "Audio Systems",
              "Computational Art",
              "Interactive Design",
              "Research & Development",
            ].map((tag, index) => (
              <motion.span
                key={index}
                className="inline-block px-2 py-1 rounded-md border border-primary/30 bg-primary/10 text-primary font-['JetBrains_Mono'] text-[10px] tracking-tighter uppercase"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.5 + index * 0.1, duration: 0.3 }}
                whileHover={{
                  scale: 1.05,
                  backgroundColor: "rgba(var(--primary), 0.2)",
                }}
              >
                {tag}
              </motion.span>
            ))}
          </motion.div>

          {/* Bio content */}
          <motion.div
            className="grid grid-cols-1 gap-5 lg:grid-cols-3 mt-6"
            variants={containerVariants}
          >
            {/* Bio content - without card */}
            <motion.div
              className="lg:col-span-2 text-left font-neue-haas"
              variants={itemVariants}
            >
              <div className="flex flex-col relative z-10 max-w-2xl">
                <p className="mb-4 sm:mb-6 text-sm sm:text-base leading-relaxed">
                  At the resonant intersection where technology meets creative expression, I craft experiences where sound, image, and code converge meaningfully
                </p>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </div>

      {/* Mouse position threshold indicator */}
      <div
        className="absolute left-0 w-full pointer-events-none flex items-center justify-between px-6"
        style={{
          top: "100vh",
          height: "2px",
        }}
      ></div>
    </div>
  );
};

export default Hero;
