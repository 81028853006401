// Exhibition data
export const exhibitionData = [
  {
    date: "03/2024",
    project: "Legis 75",
    location: "Marainenplatz, Munich",
    medium: ["Projection Mapping", "Audio/Visual"],
    description: "Interactive installation for the 75th anniversary of the German Basic Law."
  },
  {
    date: "10/2019",
    project: "Hemispheres",
    location: "Most Wanted: Music Conference",
    medium: "Audio/Visual Installation",
    description: "The Construction/Deconstruction of Parallelism. Multi-channel installation with Lukas Taido."
  },
  {
    date: "10/2019",
    project: "ECM Flow",
    location: "Seoul, Korea",
    medium: "Installation",
    description: "ECM Records 50 Years Anniversary installation with mathis-nitschke."
  },
  {
    date: "06/2017",
    project: "Savoir Vivre I-IV",
    location: "Heinrich Böll Stiftung, Berlin",
    medium: "Concert",
    description: "Audio performance with ballet."
  },
  {
    date: "02/2017",
    project: "Savoir Vivre I-IV",
    location: "NK, Neuköln",
    medium: "Concert",
    description: "Audio performance series."
  },
  {
    date: "11/2015",
    project: "CCC Mapping",
    location: "Chaos Computer Club Kongress, Hamburg",
    medium: ["Audiovisual", "Concert", "Mapping"],
    description: "Generative video art for dome mapping."
  },
  {
    date: "10/2011",
    project: "Transy U Digital Art Festival",
    location: "Kentucky, USA",
    medium: ["Screening", "Installation"],
    description: "Digital art exhibition."
  },
  {
    date: "11/2011",
    project: "Simultan Festival",
    location: "Timisoara, Romania",
    medium: ["Screening", "Installation"],
    description: "Media art festival exhibition."
  },
  {
    date: "10/2010",
    project: "Form & Zeit",
    location: "Mojo Club, Gran Canaria",
    medium: ["Screening", "Installation"],
    description: "Audio-visual installation with Aristides Garcia."
  },
  {
    date: "03/2010",
    project: "Fehlfaktor",
    location: "Berghain, Berlin",
    medium: "Concert",
    description: "Tokyo/Berlin artist meeting with Frank Bretschneider, Kyoka (Raster Noton)."
  },
  {
    date: "09/2009",
    project: "Fehlfaktor",
    location: "Smart Project Space, Amsterdam",
    medium: "Concert",
    description: "Performance with Scanner, Raster Noton (Klitekture Records)."
  },
  {
    date: "07/2009",
    project: "Form & Zeit",
    location: "Cervantes Institute, Berlin",
    medium: "Concert",
    description: "Audiovisual performance."
  },
  {
    date: "12/2008",
    project: "Canarias Crea",
    location: "Berghain Kille Kill, Berlin",
    medium: "Concert",
    description: "Festival performance."
  },
  {
    date: "11/2008",
    project: "Electroacoustic Festival",
    location: "Auditorium Adan Martin, Tenerife",
    medium: ["Workshop", "Concert"],
    description: "Workshop and live performance."
  },
  {
    date: "05/2008",
    project: "Fehlfaktor",
    location: "Berghain Kille Kill, Berlin",
    medium: "Concert",
    description: "Live electronic music performance."
  },
  {
    date: "02/2008",
    project: "Kille Kill",
    location: "Berghain, Berlin",
    medium: "Concert",
    description: "Live electronic music performance."
  }
];

// Installation data
export const installationData = [
  {
    date: "04/2018",
    project: "Enjoy Complexity",
    location: "Theater Dortmund",
    type: "Installation",
    description: "Interactive multimedia installation exploring complexity."
  },
  {
    date: "09/2017",
    project: "My Lonesome Hologram",
    location: "DOK Festival, Leipzig",
    type: "VR Installation",
    description: "VR installation with Frank Genot, Nina Weseman and Daniel Hengst."
  },
  {
    date: "11/2010",
    project: "Sony Center",
    location: "London",
    type: "Installation",
    description: "Interactive installation."
  },
  {
    date: "07/2009",
    project: "Sound Matrix",
    location: "Ontario College of Art & Design, Toronto",
    type: "Sound Installation",
    description: "4x4 interactive sound matrix for live performance."
  },
  {
    date: "07/2009",
    project: "Cervantes Institute",
    location: "Berlin",
    type: "Audio-Video Installation",
    description: "Form und Zeit with Aristides Garcia."
  }
];

// Collaboration data
export const collaborationData = [
  {
    partner: "ARRI Media & BMW",
    project: "BMW Installation Paris",
    role: "Unity/DSP Developer",
    description: "Interactive installation for BMW showroom in Paris."
  },
  {
    date: "07/2018",
    partner: "Micropsi Industries",
    project: "MINT UIX",
    role: "UI Designer",
    description: "App design for industrial robotics control interface."
  },
  {
    date: "04/2017",
    partner: "Micropsi Industries",
    project: "OpenGL Development",
    role: "System Developer",
    description: "3D Node system and UI design for robotics visualization."
  },
  {
    date: "07/2016",
    partner: "AMP Sound Branding & Porsche",
    project: "Porsche Soundlab",
    role: "System & Audio Developer",
    description: "Sound engineering for Porsche brand experience."
  },
  {
    date: "07/2015",
    partner: "Sony UK",
    project: "Sony Robot Arm",
    role: "System Developer",
    description: "Control systems for robotic installation with Phill Clandillon."
  },
  {
    date: "07/2018",
    partner: "Pranke",
    project: "Music Video",
    role: "3D Animator",
    description: "3D animation for music video."
  },
  {
    date: "07/2014",
    partner: "AGF & Vladislav Delay",
    project: "CGL - Custom Granular Live Tool",
    role: "DSP Developer",
    description: "MaxMSP/Jitter tool for live performance."
  },
  {
    date: "07/2014",
    partner: "AGF & Vladislav Delay",
    project: "Raster Noton Tour 2012",
    role: "OpenGL System Developer",
    description: "Real-time visualization system for live performance."
  },
  {
    date: "01/2014",
    partner: "Coloredo Software",
    project: "Pitch Tracking Tools",
    role: "DSP Developer",
    description: "Polyphonic pitch tracking and OpenGL data visualization."
  },
  {
    partner: "Ontario College Art & Design",
    project: "Sound Matrix",
    role: "DSP Developer",
    description: "4x4 Sound Matrix for live performance (MaxMSP/Jitter)."
  },
  {
    partner: "Klitekture Records",
    project: "Krater",
    role: "Remixer",
    description: "Audio remix released on vinyl."
  },
  {
    date: "01/2014",
    partner: "Lauma",
    project: "Visuals for Vladislav Delay & AGF",
    role: "Visual Artist",
    description: "Visuals for Raster Noton artists (2011)."
  },
  {
    date: "01/2018",
    partner: "AMP Sound Branding",
    project: "Audio Analyze App",
    role: "System Developer",
    description: "Audio analysis application for sound branding agency."
  },
  {
    date: "01/2014",
    partner: "Frank Mueller",
    project: "Surround Tools",
    role: "Developer",
    description: "Max for Live Surround tools for 5.1 & 4.1 channels."
  }
];

// Theater data
export const theaterData = [
  {
    date: "2017-2018",
    production: "Einstein on the Beach",
    venue: "Oper Dortmund",
    role: "Video Director",
    description: "Opera by Philip Glass and Robert Wilson. Concepts, art, coding and interaction design."
  },
  {
    date: "2016-2017",
    production: "Yesterday",
    venue: "Multiple Theaters (Oslo, Strassbourg, Arras)",
    role: "Video Director",
    description: "Annie Dorson's algorithmic composition performance."
  },
  {
    date: "2015",
    production: "Hamlet",
    venue: "Shakespear Theater Dortmund",
    role: "Video Artist / Developer",
    description: "Video art for Hamlet with Daniel Hengst, directed by Kay Voges."
  },
  {
    date: "2016",
    production: "Genese Dada",
    venue: "Museum Rolandseck",
    role: "Installation Artist",
    description: "Audio-tracking installation with Max Walter and Max Weseman."
  },
  {
    date: "2015",
    production: "Nature Sound Synthesis",
    venue: "Frankfurter Theater",
    role: "DSP Developer",
    description: "Real-time sound design tools for theater (with Ole Schmidt)."
  }
];

// Software Development data
export const softwareData = [
  {
    date: "Ongoing",
    project: "Grid Performer",
    type: "VST Plugin",
    technologies: ["C++", "DSP"],
    description: "VST DSP plugin for live performance (unreleased)."
  },
  {
    date: "207",
    project: "FFT-Live",
    type: "Performance Tool",
    technologies: ["Max/MSP"],
    description: "Performance instruments for Jazz musician Joachim Zoepf."
  },
  {
    date: "2014",
    project: "Bezier Curve tool",
    type: "Software Component",
    technologies: ["Java", "Max/MSP"],
    description: "Java-based Bezier Curve external for MaxMSP."
  },
];