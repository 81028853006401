import { useState, useCallback, useRef } from "react";

/**
 * Custom hook for tracking mouse position with throttling for performance
 * @returns {{ mousePosition: {x: number, y: number}, handleMouseMove: (e: MouseEvent) => void }}
 */
export const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const throttleRef = useRef(false);

  const handleMouseMove = useCallback((e) => {
    if (throttleRef.current) return;

    throttleRef.current = true;

    requestAnimationFrame(() => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY,
      });
      throttleRef.current = false;
    });
  }, []);

  return { mousePosition, handleMouseMove };
};
